@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PoppinsRegular";
  src: url("./Fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBlack";
  src: url("./Fonts/Poppins-Black.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("./Fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("./Fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("./Fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsThin";
  src: url("./Fonts/Poppins-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica";
  src: url("./Fonts/helvetica_neu_bold.ttf") format("truetype");
}
@font-face {
  font-family: "BiyaSerifItalic";
  src: url("./Fonts/DMSerifDisplay-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BiyaSerifRegular";
  src: url("./Fonts/DMSerifDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "curly";
  src: url("./Fonts/Pacifico-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope";
  src: url("./Fonts/Manrope/static/Manrope-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Italiana";
  src: url("./Fonts/Italiana/Italiana-Regular.ttf") format("truetype");
}

::-webkit-scrollbar {
  height: 0px; /* height of horizontal scrollbar ← You're missing this */
  width: 5px; /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
::-webkit-scrollbar-track {
  border-radius: 80px;
  background: #5f6469;
}

::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background: #b0b0b0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.curly {
  font-family: "curly";
}

.salon-bg {
  background: center / cover no-repeat url("./Images/salonSpace3.jpg");
  /* animation: scrollBackground 10s linear infinite; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background {
  position: relative;
  height: 100vh;
  z-index: 0;
}

.background::before {
  background-image: url("./Images/sceptreImg.jpg");
  background-size: 160vw 100%;
  background-repeat: repeat-x;
  background-position: right;
  animation: slideleft 3s infinite linear;
  -webkit-animation: slideleft 3s infinite linear;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
}

@keyframes slideleft {
  from {
    background-position: right;
    /* transform: translateX(0); */
  }
  to {
    background-position: left;
    /* transform: translateX(-50%); */
  }
}

@keyframes scrollBackground {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.service-background {
  background-image: url("./Images/salonInterior.jpg");
  background-size: 60vw 100%;
  background-repeat: no-repeat;
  background-position: right;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat1 .cat01 {
  background-image: url("./Images/hand.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat1 .cat02 {
  background-image: url("./Images/hand2.png");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}
.cat1 .cat03 {
  background-image: url("./Images/leg.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat2 .cat01 {
  background-image: url("./Images/braids.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat2 .cat02 {
  background-image: url("./Images/natHstyling.png");
  background-size: 50% 70%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}
.cat2 .cat03 {
  background-image: url("./Images/styling.png");
  background-size: 57%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat2 .cat04 {
  background-image: url("./Images/vipstyling.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat3 .cat01 {
  background-image: url("./Images/kiddieshair.png");
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}
.cat3 .cat02 {
  background-image: url("./Images/kiddiesstyling.png");
  background-size: 43%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat3 .cat03 {
  background-image: url("./Images/kiddiesbraids.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat4 .cat01 {
  background-image: url("./Images/wigdesign.png");
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat4 .cat02 {
  background-image: url("./Images/wigstyling.png");
  background-size: 43%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}
.cat4 .cat03 {
  background-image: url("./Images/wiglaundry.png");
  background-size: 47%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

.cat4 .cat04 {
  background-image: url("./Images/wigpurchase.png");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: slideleft-2 25s infinite linear;
  -webkit-animation: slideleft-2 25s infinite linear; */
  z-index: 0;
}

@keyframes slideleft-2 {
  0% {
    background-position: right;
    /* transform: translateX(0); */
  }
  50% {
    background-position: left;
  }
  100% {
    background-position: right;
    /* transform: translateX(-50%); */
  }
}

.img-carousel img {
  width: 100%;
  transition: transform 2s ease-in-out;
}

/* Slide in from the left */
.img-carousel img {
  transform: translateX(100%);
}

.img-carousel img.active {
  transform: translateX(0);
}

.service:hover img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  transition: margin-top 1s ease-out;
}

.service:not(:hover) img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  transition: margin-top 1.5s ease-in;
}

.service:hover div {
  color: white;
}

.service:not(:hover) div {
  color: black;
}

.service:not(:hover) img {
  transform: scale(2);
  transition: transform 1s ease-in;
}

.service:hover img {
  transform: scale(1);
  transition: transform 1s ease-in;
}

.service:not(:hover) > .service-title {
  margin-top: 55px;
  /* transition: margin-top 1s ease-in; */
}

.service:hover > .service-title {
  margin-top: 12px;
  /* transition: margin-top 1s ease-in; */
}

.service:hover .describe {
  opacity: 1;
  transition: opacity-top 3s ease-in;
}

.service:not(:hover) .describe {
  opacity: 0;
  transition: opacity-top 1.5s ease-out;
}

/* .iframe-container iframe {
  min-height: 800px !important;
  width: 100% !important;
  border: 2px solid yellow;
} */
